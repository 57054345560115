.article-view{
    display: flex;
    flex-direction: column;
    padding: 30px;
}
.summary-articlesview{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
}
.summary-articlesview p {
    margin-bottom: 5px;
}
.av-container{
    display: flex;
}
.av-container-sec-1, .av-container-sec-2{
    flex: 50%;
    
}
.av-container-sec-2{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    overflow: hidden; 
    margin-left: auto;
    margin-right: auto;
    
}
.av-container-sec-2 img{
    width: 100%;
    object-fit:fill;
    max-width: 800px;
    max-height: 400px;
    border-radius: 12px; 
}
.av-container-sec-1{
    display: flex;
    flex-direction: column;
    color: grey;
    gap: 15px;
    justify-content: center;
    align-items: center;
}
.article-view>p{
    color: black;
}
.article-view>p{
    margin-top: 20px;
}
.article-suggestions{
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    border-top: 2px solid black;
    margin-top: 20px;
}
.article-suggestions>div{
    flex: 50%;
    max-width: 50%;
    margin-top: 30px;
}
.av-container-sec-1 b{
    color: black;
    font-size: 1.4rem;
}
.description{
    text-align: justify;
    padding: 160px;
    margin-top: -100px;
}

@media(max-width:1180px){
    .av-container{
        display: flex;
        flex-direction: column-reverse;

    }
    .av-container-sec-2{
        display: flex;
        justify-content: center;
    }
    .av-container-sec-1{
        margin-top: 10px;
        padding: 10px;
    }
    .av-container-sec-2 img{
        max-width: 90%;
    }
    .article-suggestions>div{
        flex: 100%;
        max-width: 100%;
    }
}

@media(max-width:964px){
    .description{
        text-align: justify;
        padding: 0px;
        margin-top: 0px;

    }
    .av-container-sec-2 img {
        width: 100%;
        object-fit: contain;
        height: 315px;
        border-radius: 32px;
    }
    .av-container-sec-1 {
        margin-top: 10px;
        padding: 0px;
        text-align: center;
    }
}

@media(max-width:500px){
    .summary-articlesview{
        width: 350px;
    }
}
@media(max-width:350px){
    .summary-articlesview{
        width: 290px;
    }
}