.report {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.report h4{
    color: brown;
    text-align: center;
    font-size: 3rem;
    font-weight: 400;
    font-family: 'Port Lligat Slab';
}
.report .carousel {
    text-align: center;
    width: 60%;
    height: 40%;
}

.btn{
    margin-top: 50px;
    margin-bottom: 20px;
}

.btn button{
    padding: 0.5rem 1rem;
  text-decoration: none;
  display: inline-block;
  border-radius: 12px;
  height: fit-content;
  width: fit-content;
  background-color: #922820;
  color: white;
  margin-right: 10px;
  font-size: 1rem;
  box-shadow: none;
  border: none;
}

@media screen and (max-width:767px) {
    .report h4{
        font-size: 2rem;
    }
    
}