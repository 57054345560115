
  /* chatbox */
  #fixed-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
  }
  
  #fixed-icon img {
    width: 50px;
    height: auto;
    cursor: pointer;
  }
  
  .chatbox {
    font-family: 'Poppins';
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 10px;
    max-width: 90%;
    width: 400px;
    background-color: #f5f5f5;
    background-color: rgba(245, 245, 245, 1);
    border-radius: 12px;
    opacity: 1;
  
  
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 600px) {
    .chatbox {
      width: 80%;
      margin: 10px auto;
      bottom: 0;
      position: fixed;
    }
  
    #fixed-icon {
      bottom: 0;
      z-index: 999;
    }
    .chatbox {
     
      right: 30px;
      margin: 10px;
      max-width: 90%;
      width: 300px;
     
    
    }
  
  }
  
  /* Rest of the styles remain the same */
  .chat-container {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: 2px solid #922820;
    overflow: hidden;
    padding: 15px;
    z-index: 2;
  }
  
  .chat-log {
    height: 40px;
    overflow-y: auto;
    border-bottom: 1px solid #0c0b0b;
  }
  
  #chatbox {
    overflow-y: auto;
    border-bottom: 1px solid #0c0b0b;
  }
  
  #userInput {
    width: calc(90%);
    height: 100px;
    padding: 10px;
    border: none;
    border-top: 1px solid #ddd;
    margin-bottom: 15px;
    resize: none;
  }
  
  .sendbutton {
    width: 120px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    padding: 5px;
    border-radius: 8px;
    margin-left: 15px;
    margin-bottom: 5px;
  
  
  }
  
  .sendbutton:hover {
    background-color: #0056b3;
  }
  
  .visible {
    display: block;
  }
  
  .collapse {
    display: none;
  }
  