.sec1 .sec1-carousel {
    margin-top: 80px;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 5rem;
}

.sec1 .sec1-carousel img {
    border-radius: 1.5625rem;

}

.sec1-carousel .control-dots {
  bottom: -10px;
  }
  
  .sec1-carousel .control-dots .dot {
    width: 10px; 
    height: 10px; 
    margin: 0 5px; 
    background-color: #ffffff; 
  }
  
  .sec1-carousel .control-dots .dot.selected {
    background-color: #333; 
  }
  .carousel.carousel-slider {
    border-radius: 1.5625rem;
  }
  
  
  
  

@media screen and (max-width:767px) {
    .sec1 .sec1-carousel .sec2 .sec3 {
        padding-left: 1.5rem;
        padding-right: 1.5em;
        padding-bottom: 2rem;
    }
}



.sec2 {
    padding-left: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-right: 3rem;
    background-color: #f8f9fa !important;
    padding-top: 3rem;
    margin-bottom: 30px;
}

.sec2-header {
    gap: 30px;
    margin-right: 30px;
}

.sec2-header h1 {
    color: #000;
    font-family: "Port Lligat Slab";
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.sec2-header h2 {
  color: #000;
  font-family: "Port Lligat Slab";
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sec2-header p {
    color: #000;
    text-align: justify;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    line-height: 1.5;
}

.sec2-header button {
    background-color: #922820;
    color: white;
    padding: 0.5rem 1rem;
    text-decoration: none;
    display: inline-block;
    border-radius: 12px;
    height: fit-content;
    width: fit-content;
    margin-bottom: 30px;
    border: none;
    box-shadow: none;
}

.sec2 .middleimg img {
    max-width: 400px;
    height: auto;
}

.sec2 .links {
    display: flex;
    flex-direction: column;
}

.navigation-links a {
    display: none;
}

.navigation-links a.visible {
    display: inline-block;
    margin-right: 10px;
    text-decoration: none;
    color: #000;
}


@media screen and (min-width:1280px) {
    .sec2-header p {
        width: 600px;
    }

    .sec2 {
        height: 25rem;
    }
}

@media screen and (min-width:767px) and (max-width:1000px) {
    .sec2 .middleimg img {
        height: auto;
        max-width: 300px;
    }

}

@media screen and (max-width:767px) {
    .sec2 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .sec2 .middleimg img {
        height: 200px;
    }
    .sec2-header h1{
        font-size: 2.2rem;
    }
    .sec2-header h2{
      font-size: 2.2rem;
  }

}


.sec3 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-left: 3rem;
    padding-right: 3rem;

}

.sec3 .sec3-left img {
    max-height: 380px;
    object-fit: cover;
    border-radius: 4.25rem 0rem;
}

.sec3-right p,
h5 {
    font-size: 1.2rem;
    font-weight: 550;
    color: #922820;
    font-family: 'Poppins';
    margin-right: 10px;
    
}

.sec3-right h2 {
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Poppins';
    margin-bottom: -1%;
}

.sec3-right {
    line-height: 1;
}

.sec3-right .rightline {
    display: flex;
    flex-direction: row;
    margin-bottom: -7%;
}

.sec3-right p:nth-child(4) {
    font-size: 1.2rem;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 1.5;
  color: #000;
}


.sec3 .sec3-right img {
    width: 100%;
    max-height: 330px;
    object-fit: cover;
    border-radius: 4.25rem 0rem;
    margin-bottom: 30px;
}

.sec3 .sec3-right h3 img {
    margin-top: 20px;
}

.sec3-right button {
    margin-top: -5%;
    background-color: #922820;
    color: white;
    padding: 0.5rem 1rem;
    text-decoration: none;
    display: inline-block;
    border-radius: 12px;
    height: fit-content;
    width: fit-content;
    margin-bottom: 30px;
    border: none;
    box-shadow: none;
}

@media screen and (min-width:1080px) {
    .sec3-right p:nth-child(4) {
        width: 700px;
    }
    .sec3 .sec3-left {
        margin-right: 20px;

    }
    .sec3 .sec3-left img {
        width: 100%;
        max-height: 380px;
        object-fit: cover;
        border-radius: 4.25rem 0rem;
    }
}
@media screen and (min-width:767px) and (max-width:1080px) {
    .sec3 .sec3-left img {
        max-height: 320px;
        object-fit: cover;
        border-radius: 4.25rem 0rem;
        margin-right: 30px;
    }
}

@media screen and (max-width:767px) {
    .sec3 {
        display: flex;
        flex-direction: column;
    }

    .sec3 .sec3-left img {
        width: 100%;
        max-height: 380px;
        object-fit: cover;
        border-radius: 4.25rem 0rem;
    }
}

@media screen and (min-width:1550px) {
    .sec3 .sec3-left img {
        margin-left: 30%;
    }
    .sec2-header{
        margin-right: 10%;
    }
    
}

.image-container {
    position: relative;
    display: inline-block;
  }
  
  .image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }

  .overlay-text button{
    background-color: #922820;
    padding: 10px;
    width: fit-content;
    height: fit-content;
    border-radius: 12px;
    box-shadow: none;
    border: none;
  }
  .overlay-text button a{
    text-decoration: none;
    color: #FFF;
  }

  @media only screen and (max-width: 500px) {
    .overlay-text  {
      font-size: 5px;
      padding: 8px;
    }
    .overlay-text button{
        padding: 5px;
        font-size: 5px;
    }
  }
  @media only screen and (min-width: 500px) and (max-width:767px) {
    .overlay-text  {
      font-size: 8px;
      padding: 8px;
    }
    .overlay-text button{
        padding: 5px;
        font-size: 7px;
    }
  }
  @media only screen and (min-width: 767px) and (max-width:1000px) {
    .overlay-text  {
      font-size: 12px;
      padding: 8px;
    }
    .overlay-text button{
        padding: 5px;
        font-size: 10px;
    }
  }


  /* chatbox */
  #fixed-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
  }
  
  #fixed-icon img {
    width: 50px;
    height: auto;
    cursor: pointer;
  }
  
  .chatbox {
    font-family: 'Poppins';
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 10px;
    max-width: 90%;
    width: 400px;
    background-color: #f5f5f5;
    background-color: rgba(245, 245, 245, 1);
    /* Set background color with full opacity */
    border-radius: 12px;
    opacity: 1;
  
  
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 600px) {
    .chatbox {
      width: 90%;
      margin: 10px auto;
      bottom: 0;
      position: fixed;
    }
  
    #fixed-icon {
      bottom: 0;
      z-index: 999;
    }
  
  }
  
  /* Rest of the styles remain the same */
  .chat-container {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: 2px solid #922820;
    overflow: hidden;
    padding: 15px;
    z-index: 2;
  }
  
  .chat-log {
    height: 40px;
    overflow-y: auto;
    /* padding: 10px; */
    border-bottom: 1px solid #0c0b0b;
  }
  
  #chatbox {
    overflow-y: auto;
    border-bottom: 1px solid #0c0b0b;
  }
  
  #userInput {
    width: calc(100%);
    height: 100px;
    padding: 10px;
    border: none;
    border-top: 1px solid #ddd;
    margin-bottom: 15px;
    resize: none;
  }
  
  .sendbutton {
    width: 120px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    padding: 5px;
    border-radius: 8px;
    margin-left: 15px;
    margin-bottom: 5px;
  
  
  }
  
  .sendbutton:hover {
    background-color: #0056b3;
  }
  
  .visible {
    display: block;
  }
  
  .collapse {
    display: none;
  }
  