.video-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-popup {
    max-width: 80%;
    max-height: 80%;
  }
  
  .video-popup video {
    width: 100%;
    height: 100%;
  }
  



.gallery{
    text-align: center;
    font-size: 4rem;
    color: #922820;
    font-weight: 450;
}



.Gsec1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    margin-bottom: 50px;
    
    
}

.Gsec1-img1{
    height: 300px;
    width: 500px;
    border-radius: 1.3rem;
}

.Gsec1-vid1{
    height: 300px;
    width: 500px;
    border-radius: 1.3rem;
}

@media screen and (max-width:767px) {
    .Gsec1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .Gsec1-img1{
        height: 200px;
        width: 300px;
    }
    
    .Gsec1-vid1{
        height: 350px;
        width: 300px;
    }
    
}
@media screen and (min-width:767px) and (max-width:1080px) {
    .Gsec1{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 40px;
        margin-bottom: 50px;
    }
    .Gsec1-img1{
        height: 180px;
        width: 300px;
    }
    
    .Gsec1-vid1{
        height: 300px;
        width: 300px;
    }
}
