.gs {
    width: 100%;
}
.gs-container-sec-1-sub{
    display: flex;
    flex-direction: column;
    width: 400px;
}

.card-desc{
    text-align: justify;
    max-width: 450px;
    height: 200px;
    overflow: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-head{
    color: #000;
    font-weight: 550;
    max-width: 450px;
    text-align: justify;
    margin-top: 10px;
}

.gs-contain{
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.gs-container-sec-1-item {
    box-sizing: border-box; 
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-right: 60px;
    gap: 30px;
}

.gs-container-sec-1{
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    justify-content: center;
}
.gs-container-sec-2 {
    flex: 50%;
}

.gs-container-sec-2-box {
    background-color: lightgray ;
    color: black;
    padding: 15px;
    padding-top: 30px;
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: Port Lligat Sans;
    width: 100%;
}

.gs-container-sec-2-box img {
    width: 45%;
    height: 55vh;
    object-fit: fill;
    border-radius: 1.375rem;
}

.gs-container-sec-2-box h6 {
    margin-top:10px;
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 1px;
}

.gs-container-sec-2-box .tags {
    display: flex;
    gap: 20px;
}

.gs-container-sec-2-box .tags .tag-box {
    background-color: #F1F5F9;
    border-radius: 10px;
    color: black;
    padding: 5px;
    font-size: .9rem;
}

.gs .box-footer {
    display: flex;
    gap: 30px;
    padding-top: 20px;
}

.gs .box-footer>div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.gs .box-footer>div .rIcon {
    font-size: 1.3rem;
}

.gs-container-sec-1 {
    margin-top: 10px;
    display: flex;
    gap: 20px;
    max-width: 100%;
}
.gs-container-sec-1-item-contain{
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 700px;
}


.gs-container-sec-1-item {
    gap: 10px;
    cursor: pointer;
}

.gs-container-sec-1 .gs-container-sec-1-item-divider {
    width: 50%;
    top: 0px;
    height: 2px;
    background-color: #D9D9D9;
    margin-top: 20px;
}

.gs-container-sec-1-item-content {
    display: flex;
    flex-direction: column;
}

.gs-container-sec-1-item-content span {
    max-width: 90%;
    font-weight: 450;
    color: #969696;
}

.gs-container-sec-1-item-content ul li {
    max-width: 90%;
    font-weight: 450;
    color: #969696;
}

.gs-container-sec-1-item-content ul {
    padding-top: 10px;
}

.gs-container-sec-1-item button {
    width: 80px;
    font-size: .7rem;
}

.gs-container-sec-1-item * {
    transition: .3s;
}

.gs-container-sec-1-item-divider {
    width: 50%;
    height: 2px;
    background-color: #DADADA;
    margin-top: 20px;
}

.gs-container-sec-1-item-divider.active {
    background-color: black;
    height: 2px;
    margin-top: 15px;
}

.gs-container-sec-1-item img {
    width: 450px;
    height: 258px;
    min-height: 150px;
    min-width: 150px;
    border-radius: 1.375rem; 
    border: 1px solid #000;
    object-fit: contain; 
}

@media(max-width:964px) {

    .gs-container-sec-1,
    .gs-container-sec-2 {
        flex: 100%;
    }
    .gs-container-sec-1-sub {
        display: flex;
        flex-direction: column;
        width: 340px;
    }
    .gs-container-sec-2 {
        display: none;
    }

    .gs-container-sec-1 .gs-container-sec-1-item-divider {
        width: 75%;
    }

    .gs-container-sec-1 {
        align-items: center;
    }
    .gs {
        padding-top: 0px;
    }
    .gs-container-sec-1-item img {
        width: 100%;
        height: auto;
        min-width: 100px;
        min-height: 100px;
        object-fit: contain;
    }
  
    .gs-container-sec-1-item {
        box-sizing: border-box;
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        margin-right: 30px;
        height: auto;
    }
    .card-desc{
        text-align: justify;
        max-width: 380px;
        height: 200px;
        overflow: scroll;
    }
}

@media only screen and (min-width: 965px) and (max-width: 1016px){
    .gs-container-sec-1-item {
        box-sizing: border-box;
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        margin-right: 30px;
        height: auto;
    }
    .gs-container-sec-2-box img {
        width: 45%;
        height: 40vh;
        object-fit: fill;
        border-radius: 1.375rem;
    }
}

@media screen and (min-width:1700px) {
    .gs-container-sec-1-item {
        box-sizing: border-box; 
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        margin-right: 30px;
        
    }
}


