@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    background-color: #fcfcfc;
}

.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.navbar {
    height: 100px;
    background: #FFF;
    position: relative;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}



.menu-icon {
    display: none;
}

.logo {
    margin-top: 20px;
}
.logo img{
    width: 100%;
    height: 65px;
}
.nav-elements ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 40px;
}


.nav-elements ul a {
    text-decoration: none;
    color: #000;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.nav-elements ul button {
    border-radius: 0.625rem;
    background: #922820;
    color: white;
    padding: 0.5rem 1rem;
    text-decoration: none;
    display: inline-block;
    height: fit-content;
    width: fit-content;
    font-size: 1.125rem;
    border: none;
    text-align: center;
    box-shadow: none;
}

.nav-elements ul button a {
    color: white;
}

.nav-elements ul a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
}

.nav-elements ul a.active::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
}

.right-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.right-content .mail {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.right-content .mail  a {
    text-decoration: none;
    color: #000;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
    
}

@media (max-width: 400px) {
    .container {
        padding-right: 2rem;
    }
}


@media (max-width: 1220px) {
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .logo img {
        width: 100%;
        /* padding-left: 2rem; */
        height: 50px;

    }

    .menu-icon img {
        width: 100%;
        height: 40px;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .right-content {
        display: none;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 100px;
        background-color: #fff;
        width: 0px;
        transition: all 0.3s ease-in;
        overflow: hidden;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
        z-index: 1;
    }

    .nav-elements.active {
        width: 100%;
        height: 550px;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 30px;
        /* padding-right: 2rem; */
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}


.nav-elements .dropdown {
    position: relative;
}

.dropdown-symbol {
    font-weight: normal;
    color: #888;
}

.nav-elements .dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: #000;
    z-index: 1;
}

.nav-elements .dropdown .dropdown-content {
    border: 1px solid #ebd1d1;
    width: 265px;
    padding-bottom: 10px;
    transform: translate(-30%, 0%);
   
}

.nav-elements .dropdown-content a {
    padding-left: 2rem;
    font-size: 1.1224rem;
}

.nav-elements .dropdown-content li:hover {
    background-color: #8b4513;
    color: #fff;
}

.nav-elements .dropdown-content li:hover a {
    color: #fff;
}

.nav-elements .dropdown:hover .dropdown-content {
    display: block;
}

.nav-elements .dropdown-symbol {
    margin-left: 5px;
}

@media (max-width: 1220px) {
    .nav-elements .dropdown-content {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        transition: height 0.3s ease-in-out;
    }

    .nav-elements .dropdown:hover .dropdown-content {
        display: block;
    }

    .nav-elements .dropdown-content.active {
        display: block;
    }

    .nav-elements.active {
        height: 600px;
        z-index: 999;
    }
}


.hamburger-menu-container {
    flex: 1;
    display: none;
    align-items: center;
    justify-content: flex-end;
  }
  
  .hamburger-menu {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .hamburger-menu div {
    width: 1.6rem;
    height: 3px;
    border-radius: 3px;
    background-color: #000;
    position: relative;
    z-index: 1001;
    transition: .5s;
  }
  
  .hamburger-menu div:before,
  .hamburger-menu div:after {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: #000;
    border-radius: 3px;
    transition: .5s;
  }
  
  .hamburger-menu div:before {
    transform: translateY(-7px);
  }
  
  .hamburger-menu div:after {
    transform: translateY(7px);
  }
  
#check {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 90000;
    cursor: pointer;
    opacity: 0;
    display: none;
  }
  
  #check:checked ~ .hamburger-menu-container .hamburger-menu div {
    background-color: transparent;
  }
  
  #check:checked ~ .hamburger-menu-container .hamburger-menu div:before {
    transform: translateY(0) rotate(-45deg);
  }
  
  #check:checked ~ .hamburger-menu-container .hamburger-menu div:after {
    transform: translateY(0) rotate(45deg);
  }
  
  @keyframes animation {
    from {
      opacity: 0;
      transform: translateY(15px);
    }
  
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  
  @media (max-width: 1220PX) {
    .hamburger-menu-container {
      display: flex;
    }
  
    #check {
      display: block;
    }
  
    .nav-btn {
      position: fixed;
      height: calc(100vh - 3rem);
      top: 3rem;
      left: 0;
      width: 100%;
      background-color: #000;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow-x: hidden;
      overflow-y: auto;
      transform: translateX(100%);
      transition: .65s;
    }
  
    #check:checked ~ .nav-btn {
      transform: translateX(0);
    }
  
    #check:checked ~ .nav-btn .nav-link,
    #check:checked ~ .nav-btn .log-sign {
      animation: animation .5s ease forwards var(--i);
    }
}


@media screen and (max-width: 1220px) {
    .dropdown {
      position: relative;
      display: inline-block;
    }
  
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      z-index: 1;
    }
  .dropdown:hover .dropdown-content{
    display: block;
  }
    .dropdown-content a {
      color: black;
      text-decoration: none;
      display: block;
    }
  
  }