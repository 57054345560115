.Asec1 .imgsec1 img {
    width: 100%;
    max-height: 650px;

}

@media screen and (max-width:767px) {
    .Asec1 .imgsec1 img {
        height: 100%;
    }
}

@media screen and (min-width:1550px) {
    .Asec1 .imgsec1 img {
        max-height: 800px;
    }
}


.Asec2 .Asec2-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Asec2 .Asec2-header h4 {
    font-size: 4rem;
    color: #922820;
    font-weight: 430;
    font-family: 'Port Lligat Slab';
    margin-bottom: -1%;
}


.Asec2 .Asec2-header p {
    color: #000;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    text-transform: capitalize;
    font-size: 1.06rem;
    padding-top: 20px;
    font-family: 'Poppins';
    text-align: justify;
}

.Asec2 .Asec2-header h3 {
    color: #922820;
    text-align: justify;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.06rem;
    text-transform: capitalize;
}

@media screen and (min-width:850px) {

    .Asec2 .Asec2-header h3 {
        width: 800px;
    }

    .Asec2 .Asec2-header p {
        width: 800px;
    }
}

@media screen and (max-width:850px) {
    .Asec2 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}




.Asec3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Asec3 h3 {
    color: #000;
    text-align: center;
    font-family: "Port Lligat Slab";
    font-size: 3.975rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Asec3 .sec3-div2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Asec3 .sec3-div2 h5 {
    color: #922820;
    font-weight: bold;
    font-size: 1.25rem;
}

.Asec3 .sec3-div2 img {
    height: 100%;
}

.Asec3 .mapimg {
    height: auto;
}

.Asec3 .blackmineimg {
    width: 100%;
}

@media screen and (min-width:850px) {
    .Asec3 h3 {
        margin-right: 120px;
    }

    .Asec3 .sec3-div2 h5 {
        width: 400px;
        margin-right: 120px;

    }

    .Asec3 .sec3-div2 img {
        margin-left: -28%;
    }

    .Asec3 .mapimg {
        width: 80%;
    }

}

@media screen and (min-width:850px) and (max-width:950px) {
    .Asec3 h1 {
        margin-right: 120px;
        font-size: 3rem;
    }

    .Asec3 .sec3-div2 {
        margin-left: 10%;
    }
}

@media screen and (max-width:850px) {
    .Asec3 h1 {
        font-size: 3rem;
        padding-left: 1.5rem;

    }

    .Asec3 .sec3-div2 img {
        width: 50%;

    }

    .Asec3 .sec3-div2 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .Asec3 .mapimg {
        width: 100%;
    }
}


.Asec4 {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
}

.Asec4 h3 {
    font-size: 3.5rem;
    color: #922820;
    font-weight: bold;
    font-family: 'Port Lligat Slab';
}

.Asec4 .minesname {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.Asec4 .minesname p {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Poppins';
}


.Asec4 .minesname p:nth-child(1) {
    margin-right: -25%;
}

.Asec4 .minesname p:nth-child(3) {
    margin-left: -25%;
}


.Asec4 .minesname img {
    height: 120px;
    width: 1.5px;
}

@media screen and (max-width:850px) {
    .Asec4 h1 {
        font-size: 2rem;
        color: #922820;
        font-weight: bold;
        font-family: 'Port Lligat Slab';
    }

    .Asec4 .minesname p {
        font-size: 1rem;
        font-weight: bold;
        font-family: 'Poppins';
    }

}


.Asec5 {
    padding-top: 5%;
    background: rgba(217, 217, 217, 0.30);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Asec5 .part1 {
    margin-right: 15%;
}

.Asec5 .location {
    margin-bottom: 30px;
}

.Asec5 .location img {
    margin-right: 20px;
}

.Asec5 .location a {
    color: #000;
    text-align: center;
    font-family: "Port Lligat Slab";
    font-size: 1.32463rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -10%;
}

.Asec5 .part1 p {
    color: #000;
    font-family: Poppins;
    font-size: 1.15rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 30px;

}

.Asec5 .part1 .mail {
    margin-bottom: 20px;
}

.Asec5 .part1 .mail img {
    margin-right: 15px;
}

.Asec5 .part1 .mail a {
    color: #000;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
    text-decoration: none;
    margin-top: -5%;
}

.Asec5 .part1img a img {
    width: 400px;
    max-width: 600px;
    height: 200px;

}

@media screen and (max-width:600px) {
    .Asec5 .part1 {
        margin-right: 15%;
        margin-left: 10%;
    }

}

@media screen and (max-width:500px) {

    .Asec5 .part1img a img {
        width: 300px;
    }

    .Asec4 .minesname p:nth-child(3) {
        margin-left: 10px;
    }

    .Asec4 .minesname img {
        height: 120px;
        margin-left: 25%;
    }

}

@media screen and (max-width:1180px) {
    .Asec5 {
        display: flex;
        flex-direction: column;
    }

    .Asec5 .part1img a img {
        margin-left: -10px;

    }

}

@media screen and (min-width:600px) and (max-width:1280px) {

    .Asec5 .part1img a img {
        margin-left: -20%;
        width: 400px;
        max-width: 600px;
        height: auto;
    }

}

.part1line {
    background: rgba(217, 217, 217, 0.30);
}

.part1line img {
    width: 30%;
    height: 100%;
    padding-left: 20%;
}

@media screen and (max-width:767px) {
    .part1line img {
        width: 60%;

    }

}