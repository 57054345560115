body{
    background-color: #FFF;
}
.guide-tab-item{
    height: auto;
}
.guide-tab-item .nav-link{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 4px solid #D9D9D9;
    text-align: center;
}

.guide-tab-item .nav-link:hover{
    border-bottom: 4px solid #D9D9D9;
}
.guide-tab-item .nav-link.active{
    border-bottom: 4px solid black;
    color: black;
}
.guide-header span{
    font-size: 1.5rem;
}
.guide-tabs{
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    padding: 10px 40px;
    list-style-type: none;
}
.guide-tabs li{
    width: 100%;
}
.guide-tab-sec{
    width: 100%;
}

.guide{
    min-width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    padding-top: 20px;
}
.guide-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.guide-header p{
    font-size: 1.2rem;
}
.gs-container-sec-1 {
    display: flex;
    gap: 20px;
   
}

@media(max-width:1024px){
    .guide-tab-item .nav-link{
       font-size: .55rem;
       
    }
}

@media (max-width:450px) {
    .guide-tabs{
        padding: 1px 2px;
        

    }
}
