.Msec1 .imgsec1 img {
    width: 100%;
    max-height: 650px;
}

@media screen and (max-width:767px) {
    .Msec1 .imgsec1 img {
        height: 100%;
    }
}

@media screen and (min-width:1550px) {
    .Msec1 .imgsec1 img {
        max-height: 800px;
    }
}


.Msec2 .Msec2-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Msec2 .Msec2-header h2 {
    font-size: 4rem;
    font-weight: 430;
    font-family: 'Port Lligat Slab';
    margin-bottom: -1%;
}

.Msec2 .Msec2-header p {
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    font-size: 1.06rem;
    padding-top: 20px;
    font-family: 'Poppins';
    text-align: justify;
}

.Msec2 .Msec2-header h3 {
    color: brown;
    text-align: center;
    font-size: 3rem;
    font-weight: 400;
    font-family: 'Port Lligat Slab';
}
.Msec2 .Msec2-header .roundimg {
text-align: center;
}
.Msec2 .Msec2-header .roundimg img {
    width: 20%;
    margin-right: 30px;
}


@media screen and (min-width:850px) {

    .Msec2 .Msec2-header p {
        width: 800px;
    }
}

@media screen and (max-width:850px) {
    .Msec2 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}


.secimg{
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 40px;
}
.secimg img{
    width: 15%;
    margin-right: 60px;
}
.secimg h5{
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    font-size: 1.06rem;
    padding-top: 20px;
    font-family: 'Poppins';
    text-align: justify;
}


@media screen and (min-width:850px) {
    .secimg{
        width: 800px;
    }
    .secimg h5{
        width: 600px;
    }
   
}

@media screen and (max-width:850px) {
    .secimg img{
        margin-top: 90px;
        width: 90px;
        height: 50%;
        margin-right: 60px;
    }
    .Msec2 .Msec2-header h2 {
        font-size: 3rem;
        text-align: center;
    }

.secimg{
    margin-top: 1px;
}
}

@media screen and (min-width:550px) and (max-width:850px) {
    .secimg img{
        margin-top: 50px;       
    }
}



.Msec4{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
}
.Msec4 h6{
    color: brown;
    font-size: 3rem;
    font-weight: 400;
    font-family: 'Port Lligat Slab';
}

.Msec4 img{
    text-align: center;
    width: 80%;
    max-height: 650px;
    
}


@media screen and (max-width:767px) {
    .Msec4 h6{
        font-size: 2.5rem;
    }
}

@media screen and (min-width:1550px) {
    .Msec4 img{  
        max-height: 850px;
    }
}
