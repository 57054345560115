.footerpart {
    background-color: #f5f5f5;
    padding-top: 4rem;
    padding-bottom: 2rem;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 50px;

}

.footer .Fsec1img {
    width: 160px;
    margin-bottom: 30px;
}

.footer .Fsec1 .social .socialimg {
    width: 30%;
    aspect-ratio: 3/2;
    object-fit: contain;
}


.Fsec2 {
    margin-top: -3%;
    display: flex;
    flex-direction: column;
}

.Fsec2 h2 {
    font-size: 1.25rem;
    font-weight: 500;
}

.Fsec2 a {
    text-decoration: none;
    line-height: 2;
    color: black;
}

.Fsec2 a:hover {
    color: grey;
    text-decoration: none;
}

.Fsec3 h2 {
    font-size: 1.25rem;
    font-weight: 500;
}

.Fsec3 a {
    text-decoration: none;
    line-height: 2;
    color: black;
}

.Fsec3 a:hover {
    color: grey;
    text-decoration: none;
}

.Fsec3 {
    margin-top: -3%;
    display: flex;
    flex-direction: column;
}
.Fsec4{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.Fsec4-mail{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.Fsec4 img {
object-fit: fill;
}

.Fsec4 a {
    text-decoration: none;
    color: #000;
}


.Fpart2 .Fline {
    width: 70%;
    height: 0rem;
}

.Fpart2 .Fp {
    text-align: center;

}


@media screen and (max-width:767px) {
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .footerpart{
        padding-left: 2rem;
    }

    .footer .Fsec1 .social .socialimg {
        width: 50px;
        aspect-ratio: 3/2;
        object-fit: contain;
    }

    .Fsec2 , .Fsec3 , .Fsec4{
        margin-bottom: 1.5rem;
    }
}