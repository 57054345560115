.Psec1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Psec1 h4 {
    font-size: 4rem;
    font-family: 'Port Lligat Slab';
    color: #922820;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Psec1 img {
    width: 80%;
}

@media screen and (max-width:767px) {
    .Psec1 h4 {
        font-size: 2.5rem;
    }

}



.Psec2 .Psec2-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.Psec2 .Psec2-header h2 {
    font-size: 3rem;
    font-weight: 430;
    font-family: 'Port Lligat Slab';
    text-align: center;
    color: #922820;
}

.Psec2 .Psec2-header p {
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    font-size: 1.06rem;
    padding-top: 20px;
    font-family: 'Poppins';
    text-align: justify;
}

.Psec2 .Psec2-header h6 {
    color: brown;
    text-align: center;
    font-size: 3rem;
    font-weight: 400;
    font-family: 'Port Lligat Slab';
    margin-bottom: 10px;
}

.Psec2 .Psec2-header .roundimg {
    text-align: center;
    margin-top: 30px;
}

.Psec2 .Psec2-header .roundimg img {
    width: 20%;
    margin-right: 30px;
}


@media screen and (min-width:850px) {

    .Psec2 .Psec2-header p {
        width: 800px;
    }
}

@media screen and (max-width:850px) {
    .Psec2 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}


.Psecimg {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 40px;
}

.Psecimg img {
    width: 35%;
    margin-right: 60px;
}

.Psecimg h5 {
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 2.0;
    text-transform: capitalize;
    font-size: 1.06rem;
    padding-top: 20px;
    font-family: 'Poppins';
    text-align: justify;
}


@media screen and (min-width:850px) {
    .Psecimg {
        width: 800px;
    }

    .Psecimg h5 {
        width: 600px;
    }

}


@media screen and (max-width:850px) {
    .Psecimg img {
        margin-top: 50px;
        width: 150px;
        height: 100px;
        margin-right: 60px;
    }

    .Psec2 .Psec2-header h6 {
        font-size: 2.5rem;
    }

    .Psecimg h5 {
        font-size: 0.806rem;
    }

    .Psec2 .Psec2-header h2 {
        font-size: 2.5rem;
    }

    .Psecimg {
        margin-top: 1px;
    }
}

@media screen and (min-width:550px) and (max-width:850px) {
    .Psecimg img {
        margin-top: 30px;
    }
}


.Psec4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Psec4 h3 {
    color: #922820;
    text-align: center;
    font-family: "Port Lligat Slab";
    font-size: 2.825rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Psec4 .Psec4-carousel {
    margin-top: 20px;
    width: 40%;
}

.Psec4 .Psec4-carousel img {
    border-radius: 1.5625rem;

}

.Psec4-carousel .control-dots {
    bottom: -10px;
    border: none;
    box-shadow: none;
}

.Psec4-carousel .control-dots .dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: lightgrey;
    box-shadow: none;

}

.Psec4-carousel .control-dots .dot.selected {
    background-color: #333;
}

.carousel.carousel-slider {
    border-radius: 1.5625rem;
}

.Psec4-carousel .control-prev.control-arrow:before {
    border-right-color: rgb(10, 9, 9);
}

.Psec4-carousel .control-next.control-arrow:before {
    border-left-color: rgb(10, 9, 9);
}

.Psec4 button {
    background-color: brown;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 20px;
}




@media screen and (max-width:767px) {
    .Psec4 .Psec4-carousel {
        padding-left: 1.5rem;
        padding-right: 1.5em;
        padding-bottom: 2rem;
    }

    .Psec4 .Psec4-carousel {
        width: 60%;
    }
}